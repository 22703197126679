import ctl from "@netlify/classnames-template-literals";

/**
 * This file contains the shared styles for all the input components.
 * For example: Input, Select, Autocomplete, etc.
 */
export const transitionProps = {
  enter: "transition ease duration-500 transform",
  enterFrom: "opacity-0 -translate-y-2",
  enterTo: "opacity-100 translate-y-0",
  leave: "transition ease duration-300 transform",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 -translate-y-2",
};

export const inputGroup = ctl(`
  flex
  flex-col
  w-full
`);

export const label = ctl(`
  flex
  items-center
  mb-2
  ml-2
  cursor-pointer
  text-base
  text-neutral-900
  font-bold
`);

export const labelRequired = ctl(`
  text-neutral-600
`);

export const labelDisabled = ctl(`
  !text-neutral-400
`);

export const input = ctl(`
  w-full
  rounded-xl
  border
  border-neutral-300
  bg-white
  text-neutral-900
  px-4
  py-3
  flex
  items-center
  justify-between
  caret-purple-900
  outline-none
  transition-all
  duration-200
  placeholder:text-neutral-500
  active:border-neutral-600
  active:bg-neutral-50
  active:placeholder-transparent
  focus:border-neutral-600
  focus:bg-neutral-50
  focus:placeholder-transparent
  focus:ring-0
`);

export const inputDisabled = ctl(`
  disabled:bg-neutral-50
  disabled:border-neutral-300
  disabled:text-neutral-500
  disabled:pointer-events-none
`);

export const inputError = ctl(`
  border-status-red-500
  focus:border-status-red-500
  active:border-status-red-500
`);

export const icon = ctl(`
  absolute
  bottom-3
  text-purple-500
`);

export const iconError = ctl(`
  text-status-red-500
`);

export const iconDisabled = ctl(`
  !text-neutral-500
`);

export const helperText = ctl(`
  mt-2
  ml-2
  text-sm
  text-neutral-600
`);

export const errorText = ctl(`
  mt-2
  ml-2
  text-sm
  text-neutral-600
  text-status-red-500
`);

export const helperTextDisabled = ctl(`
  !text-neutral-400
`);

export const selectButtonDisabled = ctl(`
  bg-neutral-50
  border-neutral-300
  text-neutral-500
  pointer-events-none
`);

export const selectMenu = ctl(`
  absolute
  z-20
  top-2
  w-full
  rounded-lg
  border
  border-neutral-300
  bg-white
  text-neutral-900
  flex
  flex-col
  outline-none
  transition-all
  duration-200
  max-h-[14rem]
  overflow-y-auto
`);

export const datePickerDateButton = ctl(`
  relative
  flex
  w-[331px]
  items-stretch
  align-middle
`);

export const DayPickerMenu = ctl(`
  absolute
  z-40
  top-2
  rounded-lg
  border
  border-neutral-600
  bg-white
  text-neutral-900
  flex
  flex-col
  outline-none
  transition-all
  duration-200
  overflow-visible
`);

export const selectMenuItem = ctl(`
  flex
  items-center
  justify-between
  px-4
  py-3
  border-b
  border-neutral-300
  cursor-pointer
  hover:bg-neutral-50
  last:border-b-0
`);

export const selectMultiItem = ctl(`
  flex
  items-center
  justify-between
  px-4
  py-3
  cursor-pointer
  hover:bg-neutral-50
`);
