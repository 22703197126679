import { InputHTMLAttributes } from "react";
import cx from "classnames";
import { UseFormRegister, RegisterOptions } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import * as s from "../../shared/inputStyles";

export interface Props {
  disabled?: boolean;
  error?: string;
  helperText?: string;
  id: string;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  register: UseFormRegister<any>;
  minRows?: number;
  maxRows?: number;
  value?: string;
  validationOptions?: Partial<RegisterOptions>;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
export default function TextArea({
  disabled = false,
  error,
  helperText,
  id,
  label,
  placeholder,
  onChange,
  readOnly = false,
  required = false,
  register,
  minRows = 3,
  maxRows = 15,
  value,
  validationOptions,
}: Props): JSX.Element {
  const ariaProps: Pick<
    InputHTMLAttributes<HTMLTextAreaElement>,
    "aria-label" | "aria-invalid" | "aria-describedby"
  > = {
    "aria-label": label,
    "aria-invalid": error ? "true" : "false",
    ...(error && { "aria-describedby": "helperText" }),
  };

  return (
    <div className={s.inputGroup}>
      {label && (
        <label
          className={cx(s.label, {
            [s.labelDisabled]: disabled,
          })}
          htmlFor={id}
        >
          {label}
          {required && <span className={s.labelRequired}>*</span>}
        </label>
      )}
      <div className="relative">
        <TextareaAutosize
          disabled={disabled}
          id={id}
          className={cx(s.input, {
            [s.inputError]: error !== undefined,
            [s.inputDisabled]: disabled || readOnly,
          })}
          placeholder={placeholder}
          readOnly={readOnly}
          minRows={minRows}
          maxRows={maxRows}
          value={value}
          {...register(id, {
            disabled,
            required,
            onChange,
            ...validationOptions,
          })}
          {...ariaProps}
        />
      </div>
      {helperText && (
        <span
          id="helperText"
          className={cx(s.helperText, {
            [s.helperTextDisabled]: disabled,
          })}
        >
          {helperText}
        </span>
      )}
      {error && (
        <span id="errorText" className={s.errorText}>
          {error}
        </span>
      )}
    </div>
  );
}
